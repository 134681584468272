<template>
  <div>
    <a-row class="">
      <a-col :span="16" class="pr-2">
        <a-row class="d-flex align-items-start">
          <a-col :span="19" class="pr-4">
            <label for="name" style="display: block; margin-bottom: 7.5px; line-height: 1.2 !important;">
              Name
              <span style="color: red;"> *</span>
            </label>
            <a-input v-model="form.name" size="large" id="name" placeholder="Name"></a-input>
            <div v-if="errors.name" style="color: red;">Name is required</div>
          </a-col>
          <a-col :span="5" style="padding-top: 32px;">
            <a-switch v-model="form.is_active" class="mr-1" style="border-radius: 100px !important;" />
            Active
          </a-col>
        </a-row>
        <div class="mt-4 mb-4">
          <a-checkbox class="checkbox-item" style="user-select: none;" :checked="indicateDates" @change="indicateDates = !indicateDates">
            Indicate dates
          </a-checkbox>
          <a-row v-if="indicateDates" class="mt-4">
            <a-col :span="12" class="pr-1">
              <a-form-model-item label="Start date" prop="starts_at">
                <a-date-picker
                  v-model="form.starts_at"
                  show-time
                  format="YYYY-MM-DD HH:mm:ss"
                  type="date"
                  placeholder="Pick a start date"
                  style="width: 100%;"
                  @change="startDateChange"
                />
              </a-form-model-item>
              <div v-if="errors.starts_at" style="color: red;">Start date is required</div>
            </a-col>
            <a-col :span="12" class="pl-1">
              <a-form-model-item label="End date" prop="ends_in">
                <a-date-picker
                  v-model="form.ends_in"
                  show-time
                  format="YYYY-MM-DD HH:mm:ss"
                  type="date"
                  placeholder="Pick an end date"
                  style="width: 100%;"
                  @change="endDateChange"
                />
              </a-form-model-item>
              <div v-if="errors.ends_in" style="color: red;">End date is required</div>
            </a-col>
          </a-row>
        </div>
        <div style="position: relative;">
          <label for="langs" style="display: block; margin-bottom: 7.5px; line-height: 1.2 !important;">
            Languages
            <span style="color: red;"> *</span>
          </label>
          <a-select
            v-model="form.langs"
            mode="multiple"
            style="width: 100%"
            size="large"
            placeholder="Select languages"
          >
            <a-select-option v-for="lang in langs" :key="lang.value" :value="lang.value">
              {{lang.label}}
            </a-select-option>
          </a-select>
          <div v-if="errors.langs" style="color: red;">Please select at least one language</div>
          <a-checkbox
            class="checkbox-item"
            style="user-select: none; position: absolute; top: 0; left: 90px;"
            :checked="form.langs.length == langs.length"
            @change="handleSelectAllLangs"
          >
            Select all
          </a-checkbox>
        </div>
        <div class="mt-4">
          <label style="display: block; margin-bottom: 7.5px; line-height: 1.2 !important;">
            Apps
          </label>
          <a-row v-for="(app, i) in form.apps" :key="i" class="mb-2">
            <a-col :span="8" class="pr-2">
              <a-select
                v-model="app.application_id"
                style="width: 100%"
                placeholder="Select app"
              >
              <a-select-option v-for="option in apps" :key="option.application_id" :value="option.application_id">
                <a-icon v-if="option.platform.toLowerCase() === 'android'" type="android" class="text-success" />
                <a-icon v-if="option.platform.toLowerCase() === 'ios'" type="apple" class="text-primary" />
                <a-icon v-if="option.platform.toLowerCase() === 'web'" type="global" class="text-default" />
                {{option.name}}
              </a-select-option>
              </a-select>
            </a-col>
            <a-col v-show="app.application_id !== null" :span="10">
              <a-input-group compact class="d-flex">
                <a-tooltip :trigger="['focus']" title="Min version (e.g., 4.41)" placement="topLeft" overlay-class-name="numeric-input">
                  <a-input
                    v-model="app.min_app_version"
                    style="font-size: 12px !important;"
                    placeholder="Min Version"
                    @keypress="isNumber($event)"
                    class="app-version-input"
                  />
                </a-tooltip>
                <a-input
                  style="width: 30px; pointer-events: none; backgroundColor: #fff"
                  placeholder="-"
                  disabled
                />
                <a-tooltip :trigger="['focus']" title="Max version (e.g., 4.51)" placement="topLeft" overlay-class-name="numeric-input">
                  <a-input
                    v-model="app.max_app_version"
                    style="font-size: 12px !important;"
                    placeholder="Max Version"
                    @keypress="isNumber($event)"
                    class="app-version-input"
                  />
                </a-tooltip>
              </a-input-group>
            </a-col>
            <a-col v-if="app.application_id !== null" :span="2" class="d-flex align-items-center pl-2" style="height: 32px">
              <a-icon type="delete" style="color: red; cursor: pointer;" @click="handleDeleteApp(i)" />
            </a-col>
          </a-row>
          <a-button class="pl-0 mt-2" type="link" @click="handleAddApp">+&nbsp;&nbsp;Add</a-button>
          <div v-if="errors.applications" style="color: red;">Please select at least one application</div>
        </div>
      </a-col>
      <a-col :span="8" class="text-center pl-2">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :before-upload="beforeUpload"
          style="width: 210px; height: 330px; border-radius: 2px; background-color: #F5F5F5;"
          @change="imgChange"
        >
          <img v-if="imageUrl" :src="imageUrl" alt="splashscreen image" style="width: 210px; height: 330px; border-radius: 2px;" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" style="font-size: 48px; color: #8C8C8C;" />
            <div class="ant-upload-text" style="color: #8C8C8C; font-weight: 300;">
              File size<br/>
              1400 x 2200 px
            </div>
          </div>
        </a-upload>
      </a-col>
      <a-col :span="3">
        <label for="name" style="display: block; margin-bottom: 7.5px; line-height: 1.2 !important;">
          Show limit
        </label>
        <a-input v-model="form.max_shown_count" size="large" id="max_shown_count" placeholder="0" @keypress="isNumber($event)"></a-input>
      </a-col>
    </a-row>
    <hr style="margin-left: -24px; margin-right: -24px;" />
    <a-row style="margin-top:0 !important;">
      <a-col class="text-right" :span="24">
        <CustomButton style="margin-right: 1rem;" @click="closeAddModal">
          Cancel
        </CustomButton>
        <a-button type="primary" @click="onSubmit">
          Add
        </a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import banmix from '@/services/bannermix.js'
import accessMix from '@/services/accessmix.js'
import CustomButton from '@/components/custom/button/CustomButton'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'addSplashscreen',
  mixins: [banmix, accessMix],
  components: {
    CustomButton,
  },
  props: {
    apps: {
      type: Array,
      required: true,
      default: () => [],
    },
    langs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    imageUrl: '',
    indicateDates: false,
    form: {
      is_active: true,
      name: '',
      imglink: '',
      max_shown_count: 10000000,
      imgFile: undefined,
      starts_at: undefined,
      ends_in: undefined,
      apps: [],
      langs: [],
    },
    errors: {
      name: false,
      starts_at: false,
      ends_in: false,
      applications: false,
      langs: false,
    },
  }),
  methods: {
    closeAddModal() {
      this.$emit('closeModalFunction')
      // this.$parent.$options.methods
    },
    submitAddModal() {
      this.$emit('submitModalFunction')
    },
    beforeUpload(file) {
      // console.log(this.activeTab)
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG or PNG file!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$message.error('Image must smaller than 5MB!')
        return false
      }
      return new Promise((resolve, reject) => {
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.src = _URL.createObjectURL(file)
        img.onload = () => {
          // const inProportions = Math.round(10 * (img.width / img.height)) / 10 === 2.8
          // if (!inProportions) {
          //   this.$message.error('Image must be in correct proportions - 14 : 5 (e.g. 1160 x 414 px)')
          //   reject(err)
          // } else {
          resolve()
          // }
        }
      })
    },
    imgChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      this.form.imgFile = info.file.originFileObj
      getBase64(info.file.originFileObj, imageUrl => {
        this.imageUrl = imageUrl
        this.form.imglink = imageUrl
        console.log(imageUrl)
        this.loading = false
      })
    },
    handleSelectAllLangs(e) {
      if (e.target.checked) {
        this.form.langs = this.langs.map((l) => l.value)
      }
    },
    handleAddApp() {
      this.form.apps.push({
        application_id: null,
        min_app_version: '',
        max_app_version: '',
      })
    },
    startDateChange(value, dateString) {
      if (!this.$moment(dateString).isValid()) {
        this.form.starts_at = undefined
      }
    },
    endDateChange(value, dateString) {
      if (!this.$moment(dateString).isValid()) {
        this.form.ends_in = undefined
      }
    },
    handleDeleteApp(index) {
      this.form.apps = this.form.apps.filter((app, i) => i !== index)
    },
    validateForm() {
      this.errors.name = false
      this.errors.starts_at = false
      this.errors.ends_in = false
      this.errors.applications = false
      this.errors.langs = false

      if (this.form.name === '') this.errors.name = true
      if (this.indicateDates && !this.form.starts_at) this.errors.starts_at = true
      if (this.indicateDates && !this.form.ends_in) this.errors.ends_in = true
      if (!this.form.apps.length) this.errors.applications = true
      if (!this.form.langs.length) this.errors.langs = true

      if (this.errors.name || this.errors.starts_at || this.errors.ends_in || this.errors.applications || this.errors.langs) {
        return false
      } else {
        return true
      }
    },
    onSubmit() {
      if (!this.validateForm()) return
      this.createSplashscreen()
    },
    createSplashscreen() {
      const url = '/admin/splashscreens'
      if (typeof this.form.starts_at !== 'undefined') {
        this.form.starts_at = this.$moment(this.form.starts_at).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
      }
      if (typeof this.form.ends_in !== 'undefined') {
        this.form.ends_in = this.$moment(this.form.ends_in).tz('Etc/UTC').format('YYYY-MM-DD HH:mm:ss')
      }
      // this.form.name = 'banner.' + this.activeTab + '.' + this.$moment().format('YYYY-MM-DD HH:mm')
      const data = {
        name: this.form.name,
        max_shown_count: this.form.max_shown_count,
        starts_at: this.form.starts_at,
        ends_in: this.form.ends_in,
        apps: this.form.apps,
        langs: this.form.langs,
      }
      if (!this.form.imgFile) {
        this.$notification.error({
          message: 'Upload image to create new splashscreen',
        })
        return false
      } else {
        return apiClient.post(url, data).then((response) => {
          // this.banners = response.data.data
          console.log(response)
          console.log(this.form)
          this.closeAddModal()
          this.$notification.success({
            message: 'Splashscreen "' + response.data.data.name + '" has been successfully created',
            description: '',
          })
          // return response.data.data.id
          this.putCover(response.data.data.id)
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: 'Error while creating new splashscreen',
            description: error.message,
          })
        })
      }
    },
    putCover(splashId) {
      const url = `/admin/splashscreens/${splashId}/cover`
      const formData = new FormData()
      formData.append('cover', this.form.imgFile)
      apiClient.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        // this.banners = response.data.data
        this.$notification.success({
          message: 'Splashscreen cover updated',
          description: '',
        })
        this.resetForm()
        this.submitAddModal()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating splashscreen cover',
          description: error.message,
        })
      })
    },
    resetForm() {
      this.imageUrl = ''
      this.indicateDates = false
      this.form = {
        is_active: true,
        name: '',
        imglink: '',
        imgFile: undefined,
        max_shown_count: 10000000,
        starts_at: undefined,
        ends_in: undefined,
        applications: [],
        langs: [],
      }
    },
  },
}
</script>
<style>
.avatar-uploader > .ant-upload.ant-upload-select {
  width: 100% !important;
  height: 100% !important;
}
</style>
